import * as actionTypes from '../actions';


export const initialState = {
    saved_events: [],
};


// ==============================|| SCHEDULE REDUCER ||============================== //

const UserReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_SAVED_EVENT:
            let arr =  [...state.saved_events, action.event]
            return {...state, saved_events: arr};
        case actionTypes.REMOVE_SAVED_EVENT:
            return {...state, saved_events: state.saved_events.filter(event => event !== action.event)};
        default:
            return state;
    }
};


export default UserReducer;
