import {useSelector} from 'react-redux';
import "./App.css";
import {ThemeProvider} from '@mui/material/styles';
import {CssBaseline, StyledEngineProvider} from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import {Translator} from "react-auto-translate";
import NavMotion from "./layout/NavMotion";

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);
    const currentLanguage = useSelector((state) => state.language.current);

    return (
        <StyledEngineProvider injectFirst>
            <Translator to={currentLanguage} from={'ca'} googleApiKey={'AIzaSyDCakP5VBdf7mO5kmu3In5YlSFRVoJdEls'}>

            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />

                    <NavigationScroll>
                        <Routes />
                    </NavigationScroll>


            </ThemeProvider>
            </Translator>
        </StyledEngineProvider>
    );
};

export default App;
