import { Outlet } from 'react-router-dom';

// project imports
import Customization from '../Customization';
import {CssBaseline} from "@mui/material";

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => (
    <>
        <Outlet />
        <CssBaseline />
        {/*<Customization />*/}
    </>
);

export default MinimalLayout;
