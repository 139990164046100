import * as actionTypes from '../actions';

export const initialState = {
    user: null,
    isLoggedIn: false,
};

// ==============================|| AUTH REDUCER ||============================== //

const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOG_IN:
            return {
                ...state,
                user: action.user,
                isLoggedIn: true,
            };
        case actionTypes.LOG_OUT:
            return {
                ...state,
                user: null,
                isLoggedIn: false,
            };
        default:
            return state;
    }
};

export default AuthReducer;
