import * as actionTypes from '../actions';


export const initialState = {
    scheduleData: {},
    events: [],
    popupAds: [],
    currentAdIndex: 0,
    visitorId: '',
};


// ==============================|| SCHEDULE REDUCER ||============================== //

const ScheduleReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_SCHEDULE:
            return {...state, scheduleData: action.scheduleData};
        case actionTypes.SET_SCHEDULE_EVENTS:
            return {...state, events: action.events};
        case actionTypes.SET_POPUP_ADS:
            return {...state, popupAds: action.data};
        case actionTypes.SET_CURRENT_AD_INDEX:
            return {...state, currentAdIndex: action.index};
        case actionTypes.SET_VISITOR_ID:
            return {...state, visitorId: action.id};
        default:
            return state;
    }
};


export default ScheduleReducer;
