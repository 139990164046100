// Auth Actions
export const LOG_IN = '@auth/LOG_IN';
export const LOG_OUT = '@auth/LOG_OUT';

//customization
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';


//Schedule actions

export const SET_SCHEDULE = "@schedule/SET_SCHEDULE";
export const SET_SCHEDULE_EVENTS = "@schedule/SET_SCHEDULE_EVENTS";
export const SET_POPUP_ADS = "@schedule/SET_POPUP_ADS";
export const SET_INDEX_VIDEO_SELECTED = "@schedule/SET_INDEX_VIDEO_SELECTED";
export const SET_CURRENT_AD_INDEX = "@schedule/SET_CURRENT_AD_INDEX";
export const SET_VISITOR_ID = "@schedule/SET_VISITOR_ID";

// Language actions
export const SET_LANGUAGE = "@language/SET_LANGUAGE";

//User data actions
export const ADD_SAVED_EVENT = "@user/ADD_SAVED_EVENT";
export const REMOVE_SAVED_EVENT = "@user/REMOVE_SAVED_EVENT";


