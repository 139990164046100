import {useRoutes} from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function Routes() {

    // return useRoutes([MainRoutes, AuthenticationRoutes]);
    return useRoutes([MainRoutes]);
}
