import * as actionTypes from '../actions';


export const initialState = {
    current: 'ca',
};


// ==============================|| SCHEDULE REDUCER ||============================== //

const ScheduleReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_LANGUAGE:
            return {...state, current: action.language};
        default:
            return state;
    }
};


export default ScheduleReducer;
