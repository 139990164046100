import {lazy} from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from "../layout/MinimalLayout";


const NotFound = Loadable(lazy(() => import('views/pages/not-found')));
const ScheduleScreen = Loadable(lazy(() => import('views/pages/schedule')));



// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {

        path: '/',
        element: <MinimalLayout/>,
        children: [
            {
                path: '*',
                element: <NotFound/>
            },
            {
                path: '/',
                element: <ScheduleScreen/>,
                children: [
                    {
                        path: '/:eventIdentifier',
                        element: <ScheduleScreen/>,
                        search: 'featured'
                    },
                ]
            },
        ]
};

export default MainRoutes;
