import { combineReducers } from 'redux';
import CustomizationReducer from "./reducers/CustomizationReducer";
import AuthReducer from "./reducers/AuthReducer";
import ScheduleReducer from "./reducers/ScheduleReducer";
import LanguageReducer from "./reducers/LanguageReducer";
import UserReducer from "./reducers/UserReducer";

// ==============================|| COMBINE REDUCER ||============================== //

const MainReducer = combineReducers({
    customization: CustomizationReducer,
    auth: AuthReducer,
    schedule: ScheduleReducer,
    language: LanguageReducer,
    user: UserReducer,
});

export default MainReducer;
