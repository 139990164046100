import { createStore } from 'redux';
import MainReducer from './MainReducer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Choose your storage engine

// ==============================|| REDUX - MAIN STORE ||============================== //
const persistConfig = {
    key: 'Main',
    storage,
    // Specify the reducers you want to persist
    whitelist: ['user'],
};

// [OLD] redux without persist
// const store = createStore(MainReducer);
// const persister = 'Main';

const persistedReducer = persistReducer(persistConfig, MainReducer);
const store = createStore(persistedReducer);
const persistor = persistStore(store);

export { store, persistor };
